<template>
  <div>
    <b-container fluid>
      <b-row style="margin-top: 10px">
        <b-col cols="12" class="pageTitleRwd">
          <span class="pageTitle">{{ $t("siteComparation.goodRate") }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-breadcrumb
          :items="breadcrumbItems"
          class="bread-crumb"
          style="font-size: 15px"
        />
      </b-row>
      <TimeSeparator
        :date-type="dateType"
        :date-type-clicked-idx="dateTypeClickedIdx"
        @date-type-click="handleClickDateType"
      />
      <!-- 日表 -->
      <div class="tableByDate" v-if="dateTypeClickedIdx === 0">
        <b-row align-h="center">
          <div class="table-title">
            {{ $t("siteComparation.peakWatt") }} ({{ getTimeNow }})
          </div>
          <b-table
            responsive
            :items="items"
            striped
            hover
            class="table"
            outlined
          ></b-table>
        </b-row>
        <b-row align-h="center">
          <div class="table-title">
            {{ $t("siteComparation.INVpeakWatt") }} ({{ getTimeNow }})
          </div>
          <b-table
            responsive
            :items="items"
            striped
            hover
            class="table"
            outlined
          ></b-table>
        </b-row>
      </div>
      <!-- 月表 -->
      <div class="tableByMon" v-if="dateTypeClickedIdx === 1">
        <b-row align-h="center">
          <div class="table-title">
            {{ $t("siteComparation.peakWatt") }} ({{ "time" }})
          </div>
          <b-table
            responsive
            :items="items"
            striped
            hover
            class="table"
            outlined
          ></b-table>
        </b-row>
        <b-row align-h="center">
          <div class="table-title">
            {{ $t("siteComparation.INVpeakWatt") }} ({{ "time" }})
          </div>
          <b-table
            responsive
            :items="items"
            striped
            hover
            class="table"
            outlined
          ></b-table>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import TimeSeparator from '../components/TimeSeparator'
export default {
  name: 'sitecomparation',
  components: {
    TimeSeparator
  },
  data () {
    return {
      breadcrumbItems: [
        {
          text: '首頁',
          to: {
            name: 'realtime'
          }
        },
        {
          text: '案場達成率',
          active: true
        }
      ],
      items: [
        {
          '案場 (變流器)': '東和',
          '峰瓩值 (kWh/kWp)': 3.74,
          '達成率 (%)': 100
        },
        {
          '案場 (變流器)': '東和發電111222333',
          '峰瓩值 (kWh/kWp)': 3.74,
          '達成率 (%)': 100
        },
        {
          '案場 (變流器)': '東和',
          '峰瓩值 (kWh/kWp)': 3.74,
          '達成率 (%)': 100
        }
      ],
      // fields1: [
      //   '案場 (變流器)', '峰瓩值 (kWh/kWp)', '達成率 (%)'
      // ],
      dateType: [{ title: '日' }, { title: '月' }],
      dateTypeClickedIdx: 0
    }
  },
  computed: {
    getTimeNow () {
      return 'time'
    }
  },
  methods: {
    handleClickDateType (idx) {
      this.dateTypeClickedIdx = idx
    }
  },
  created () {
    // this.axios.post('https://testfuncappbyben.azurewebsites.net/api/HttpTrigger1?code=wiyb9qU9UpNRCMG0TJ0tCCkOthFhrBAgR3iKemZfg0GigXxaruDgfw==').then(res => {
    //   console.log(res)
    // })
  }
}
</script>

<style lang="scss">
@import "../assets/scss/siteComparation.scss";
.table thead th {
  vertical-align: top;
  border-bottom: 2px solid #dee2e6;
}
.table-responsive {
  overflow-x: auto;
}
</style>
